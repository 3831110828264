<script lang="ts" setup>
import type { Collections, BlogDeCollectionItem, BlogEnCollectionItem, BlogEsCollectionItem } from '@nuxt/content';

const localePath = useLocalePath();
const { locale } = useI18n();
const route = useRoute();

const { data: blogPosts } = await useAsyncData(route.fullPath, async () => {
  const collection = ('blog_' + locale.value) as keyof Collections;

  const result = await queryCollection(collection as 'blog_de' | 'blog_en' | 'blog_es')
    .limit(3)
    .order('date', 'DESC')
    .all();

  return result as BlogDeCollectionItem[] | BlogEnCollectionItem[] | BlogEsCollectionItem[];
});
</script>

<template>
  <NuxtLayout>
    <div>
      <section class="hero is-medium is-link has-background-light">
        <div class="hero-body container has-text-centered">
          <h1 class="title is-size-2 is-size-4-mobile has-text-dark">
            {{ $t('index.hero-title') }}
          </h1>
          <h2 class="subtitle is-size-3 is-size-5-mobile has-text-primary">
            {{ $t('index.hero-subtitle') }}
          </h2>
        </div>
      </section>
      <section class="section is-small">
        <div class="container">
          <div class="content-section">
            <div>
              <p class="title is-size-4 mb-4">
                {{ $t('index.content-title') }}
              </p>
              <p>
                {{ $t('index.content-desc') }}
              </p>
            </div>
            <div>
              <p class="title is-size-4 mb-4">
                {{ $t('index.content-title-2') }}
              </p>
              <p>
                {{ $t('index.content-desc-2') }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="section is-small">
        <CardGallery />
      </section>
      <section class="section is-small container">
        <div class="has-text-centered pb-6">
          <h1 class="title is-size-2 is-size-4-mobile has-text-dark">
            {{ $t('index.product-title') }}
          </h1>
          <h2 class="subtitle is-size-3 is-size-5-mobile has-text-primary">
            {{ $t('index.product-subtitle') }}
          </h2>
        </div>
        <ProductGallery />
      </section>
      <section v-if="$i18n.locale === 'de'" class="section is-small container">
        <div class="has-text-centered pb-6">
          <h1 class="title is-size-2 is-size-5-mobile has-text-dark">
            {{ $t('index.partner-title') }}
          </h1>
          <h2 class="subtitle is-size-3 is-size-5-mobile has-text-primary">
            {{ $t('index.partner-subtitle') }}
          </h2>
        </div>
        <div class="icon-box-wrapper">
          <div class="icon-box is-flex is-align-items-center pb-6">
            <div class="icon-box-img">
              <img src="~/assets/img/icons/partner.svg" alt="" width="130" height="60" class="pb-5">
            </div>
            <div class="icon-box-text">
              <p>
                {{ $t('index.partner-text') }}
              </p>
              <a class="button is-primary mt-3" href="https://partner.42he.com/">{{ $t('index.partner-button') }}</a>
            </div>
          </div>
        </div>
      </section>
      <section v-if="$i18n.locale === 'de'" class="section is-small py-6">
        <div class="hero-body container has-text-centered">
          <h1 class="title is-size-2 is-size-4-mobile has-text-dark">
            {{ $t('index.blog-title') }}
          </h1>
          <h2 class="subtitle is-size-3 is-size-5-mobile has-text-primary">
            {{ $t('index.blog-subtitle') }}
          </h2>
        </div>
        <div class="container">
          <BlogPostList v-if="blogPosts" :blog-posts="blogPosts" />
          <div class="is-flex is-justify-content-center py-6">
            <NuxtLink :to="localePath('/blog')" class="button is-primary">
              {{ $t('index.blog-cta') }}
            </NuxtLink>
          </div>
        </div>
      </section>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.content-section {
  display: flex;
  gap: 15%;
}

.icon-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.icon-box-text {
  width: 65%;
}

.icon-box {
  gap: 40px;
  justify-content: center;
}

@media screen and (max-width: 950px) {
  .content-section {
    flex-direction: column;
    gap: 50px;
  }

  .icon-box-wrapper {
    width: 100%;
  }

  .icon-box {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  .icon-box-img {
    display: flex;
    justify-content: center;
  }

  .icon-box-text {
    width: 100%;
  }
}
</style>
